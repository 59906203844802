/* tslint:disable */
/* eslint-disable */
/**
 * passport
 * passport接口文档
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiOauth2ClientsDelete200Response } from '../models';
// @ts-ignore
import { AppModelOauth2Clients } from '../models';
// @ts-ignore
import { AppRequestOauth2CheckAttentionByUIdsRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2CheckPermissionRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2ClientOssSignUrlRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2ClientsPatchRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2ClientsPostRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2ClientsSyncPermissionRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2RefreshRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2StsRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2TokenByWeChatRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2TokenPassRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2TokenRequest } from '../models';
// @ts-ignore
import { AppRequestOauth2TokenRequestAlias } from '../models';
// @ts-ignore
import { AppRequestOauth2WechatBindMobileRequest } from '../models';
// @ts-ignore
import { AppResponseOauth2CheckAttentionByUIdsResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2CheckPermissionResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2ClientOssByKeyResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2GetIdTokenResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2RefreshResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2StsResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2TokenBySmsResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2TokenByWeChatResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2TokenPassResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2TokenResponse } from '../models';
// @ts-ignore
import { AppResponseOauth2TokenResponseAlias } from '../models';
// @ts-ignore
import { BbcloudBabyApiRequestsBatchDeletionRequest } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesBatchDeletionResponse } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesCountResponse } from '../models';
// @ts-ignore
import { BbcloudBabyApiResponsesTransitionByStateResponse } from '../models';
// @ts-ignore
import { ErrorSchema } from '../models';
/**
 * AppModelOauth2ClientsApi - axios parameter creator
 * @export
 */
export const AppModelOauth2ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 权限效验
         * @param {AppRequestOauth2CheckPermissionRequest} [appRequestOauth2CheckPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckPermissionPost: async (appRequestOauth2CheckPermissionRequest?: AppRequestOauth2CheckPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/check_permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2CheckPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端OSS文件URL签名
         * @param {AppRequestOauth2ClientOssSignUrlRequest} [appRequestOauth2ClientOssSignUrlRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientOssSignUrlPost: async (appRequestOauth2ClientOssSignUrlRequest?: AppRequestOauth2ClientOssSignUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/oss_sign_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2ClientOssSignUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端OSS临时授权码
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientOssStsGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiClientOssStsGet', 'clientId', clientId)
            const localVarPath = `/api/client/oss_sts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信授权登录
         * @param {AppRequestOauth2TokenByWeChatRequest} [appRequestOauth2TokenByWeChatRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2AuthByWechatPost: async (appRequestOauth2TokenByWeChatRequest?: AppRequestOauth2TokenByWeChatRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/auth_by_wechat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2TokenByWeChatRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发起授权
         * @param {string} responseType 
         * @param {string} clientId 
         * @param {string} redirectUri 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2AuthorizeGet: async (responseType: string, clientId: string, redirectUri: string, scope?: string, state?: string, nonce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseType' is not null or undefined
            assertParamExists('apiOauth2AuthorizeGet', 'responseType', responseType)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiOauth2AuthorizeGet', 'clientId', clientId)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('apiOauth2AuthorizeGet', 'redirectUri', redirectUri)
            const localVarPath = `/api/oauth2/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (responseType !== undefined) {
                localVarQueryParameter['response_type'] = responseType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信绑定手机号
         * @param {AppRequestOauth2WechatBindMobileRequest} [appRequestOauth2WechatBindMobileRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2BindMobilePost: async (appRequestOauth2WechatBindMobileRequest?: AppRequestOauth2WechatBindMobileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/bind_mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2WechatBindMobileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量检查关注状态
         * @param {AppRequestOauth2CheckAttentionByUIdsRequest} [appRequestOauth2CheckAttentionByUIdsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CheckAttentionByUidsPost: async (appRequestOauth2CheckAttentionByUIdsRequest?: AppRequestOauth2CheckAttentionByUIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/check_attention_by_uids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2CheckAttentionByUIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查关注状态
         * @param {string} [openid] 
         * @param {number} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CheckAttentionGet: async (openid?: string, uid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/check_attention`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (openid !== undefined) {
                localVarQueryParameter['openid'] = openid;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取客户端配置信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientInfoGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiOauth2ClientInfoGet', 'clientId', clientId)
            const localVarPath = `/api/oauth2/client_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsCountGet: async (groupBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsDelete: async (bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bbcloudBabyApiRequestsBatchDeletionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsGet: async (q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdDelete', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessCode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGanAccessCodePost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdGanAccessCodePost', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}/gan_access_code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessToken
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGanAccessTokenPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdGanAccessTokenPost', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}/gan_access_token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdGet', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdOperationsLogsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdOperationsLogsGet', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}/operations_logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestOauth2ClientsPatchRequest} [appRequestOauth2ClientsPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdPatch: async (id: number, appRequestOauth2ClientsPatchRequest?: AppRequestOauth2ClientsPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdPatch', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2ClientsPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 权限采集
         * @param {number} id 
         * @param {AppRequestOauth2ClientsSyncPermissionRequest} [appRequestOauth2ClientsSyncPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdSyncPermissionPost: async (id: number, appRequestOauth2ClientsSyncPermissionRequest?: AppRequestOauth2ClientsSyncPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOauth2ClientsIdSyncPermissionPost', 'id', id)
            const localVarPath = `/api/oauth2/clients/{id}/sync_permission`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2ClientsSyncPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建
         * @param {AppRequestOauth2ClientsPostRequest} [appRequestOauth2ClientsPostRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsPost: async (appRequestOauth2ClientsPostRequest?: AppRequestOauth2ClientsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2ClientsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsSchemaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsStatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/clients/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsTransitionsByStateGet: async (state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('apiOauth2ClientsTransitionsByStateGet', 'state', state)
            const localVarPath = `/api/oauth2/clients/transitions_by_state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CodeGet: async (clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取微信二维码票据
         * @param {string} [actionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetQrcodeTicketGet: async (actionInfo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/get_qrcode_ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actionInfo !== undefined) {
                localVarQueryParameter['action_info'] = actionInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通过手机短信获取访问令牌
         * @param {string} mobile 
         * @param {string} code 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetTokenBySmsGet: async (mobile: string, code: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobile' is not null or undefined
            assertParamExists('apiOauth2GetTokenBySmsGet', 'mobile', mobile)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiOauth2GetTokenBySmsGet', 'code', code)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiOauth2GetTokenBySmsGet', 'clientId', clientId)
            const localVarPath = `/api/oauth2/get_token_by_sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat'} grantType 
         * @param {string} clientId 
         * @param {string} [clientSecret] 
         * @param {string} [code] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetTokenGet: async (grantType: 'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat', clientId: string, clientSecret?: string, code?: string, username?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('apiOauth2GetTokenGet', 'grantType', grantType)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiOauth2GetTokenGet', 'clientId', clientId)
            const localVarPath = `/api/oauth2/get_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (grantType !== undefined) {
                localVarQueryParameter['grant_type'] = grantType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (clientSecret !== undefined) {
                localVarQueryParameter['client_secret'] = clientSecret;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查用户是否关联微信
         * @param {number} [uid] 
         * @param {string} [openid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2HasWechatUserGet: async (uid?: number, openid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/has_wechat_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (openid !== undefined) {
                localVarQueryParameter['openid'] = openid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@getIdToken
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2IdTokenGet: async (clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/id_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@jwks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2JwksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/jwks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取访问授权码(企业前后台专用)
         * @param {AppRequestOauth2TokenPassRequest} [appRequestOauth2TokenPassRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2PassTokenPost: async (appRequestOauth2TokenPassRequest?: AppRequestOauth2TokenPassRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/pass_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2TokenPassRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 刷新访问令牌
         * @param {AppRequestOauth2RefreshRequest} [appRequestOauth2RefreshRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2RefreshPost: async (appRequestOauth2RefreshRequest?: AppRequestOauth2RefreshRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2RefreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 撤销访问令牌
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2RevokePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取其他应用临时访问令牌
         * @param {AppRequestOauth2StsRequest} [appRequestOauth2StsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2StsPost: async (appRequestOauth2StsRequest?: AppRequestOauth2StsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/sts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2StsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {AppRequestOauth2TokenRequest} [appRequestOauth2TokenRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2TokenPost: async (appRequestOauth2TokenRequest?: AppRequestOauth2TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2TokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取授权中心访问令牌
         * @param {AppRequestOauth2TokenRequestAlias} [appRequestOauth2TokenRequestAlias] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2TokenPost_1: async (appRequestOauth2TokenRequestAlias?: AppRequestOauth2TokenRequestAlias, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appRequestOauth2TokenRequestAlias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2WechatEventGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/wechat_event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2WechatEventPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oauth2/wechat_event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppModelOauth2ClientsApi - functional programming interface
 * @export
 */
export const AppModelOauth2ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppModelOauth2ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 权限效验
         * @param {AppRequestOauth2CheckPermissionRequest} [appRequestOauth2CheckPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest?: AppRequestOauth2CheckPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2CheckPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端OSS文件URL签名
         * @param {AppRequestOauth2ClientOssSignUrlRequest} [appRequestOauth2ClientOssSignUrlRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest?: AppRequestOauth2ClientOssSignUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端OSS临时授权码
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientOssStsGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2ClientOssByKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientOssStsGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信授权登录
         * @param {AppRequestOauth2TokenByWeChatRequest} [appRequestOauth2TokenByWeChatRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest?: AppRequestOauth2TokenByWeChatRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenByWeChatResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发起授权
         * @param {string} responseType 
         * @param {string} clientId 
         * @param {string} redirectUri 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2AuthorizeGet(responseType: string, clientId: string, redirectUri: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2AuthorizeGet(responseType, clientId, redirectUri, scope, state, nonce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信绑定手机号
         * @param {AppRequestOauth2WechatBindMobileRequest} [appRequestOauth2WechatBindMobileRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest?: AppRequestOauth2WechatBindMobileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量检查关注状态
         * @param {AppRequestOauth2CheckAttentionByUIdsRequest} [appRequestOauth2CheckAttentionByUIdsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest?: AppRequestOauth2CheckAttentionByUIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2CheckAttentionByUIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查关注状态
         * @param {string} [openid] 
         * @param {number} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2CheckAttentionGet(openid?: string, uid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2CheckAttentionGet(openid, uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取客户端配置信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientInfoGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientInfoGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsCountGet(groupBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsCountGet(groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesBatchDeletionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOauth2ClientsDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsGet(q, page, pageSize, orderBy, select, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessCode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdGanAccessCodePost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdGanAccessCodePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessToken
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdGanAccessTokenPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdGanAccessTokenPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppModelOauth2Clients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdOperationsLogsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdOperationsLogsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestOauth2ClientsPatchRequest} [appRequestOauth2ClientsPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdPatch(id: number, appRequestOauth2ClientsPatchRequest?: AppRequestOauth2ClientsPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppModelOauth2Clients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdPatch(id, appRequestOauth2ClientsPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 权限采集
         * @param {number} id 
         * @param {AppRequestOauth2ClientsSyncPermissionRequest} [appRequestOauth2ClientsSyncPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsIdSyncPermissionPost(id: number, appRequestOauth2ClientsSyncPermissionRequest?: AppRequestOauth2ClientsSyncPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsIdSyncPermissionPost(id, appRequestOauth2ClientsSyncPermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建
         * @param {AppRequestOauth2ClientsPostRequest} [appRequestOauth2ClientsPostRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest?: AppRequestOauth2ClientsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppModelOauth2Clients>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsSchemaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsSchemaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsStatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsStatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2ClientsTransitionsByStateGet(state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbcloudBabyApiResponsesTransitionByStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2ClientsTransitionsByStateGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2CodeGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2CodeGet(clientId, redirectUri, scope, state, nonce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取微信二维码票据
         * @param {string} [actionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2GetQrcodeTicketGet(actionInfo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2GetQrcodeTicketGet(actionInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通过手机短信获取访问令牌
         * @param {string} mobile 
         * @param {string} code 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2GetTokenBySmsGet(mobile: string, code: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenBySmsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2GetTokenBySmsGet(mobile, code, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat'} grantType 
         * @param {string} clientId 
         * @param {string} [clientSecret] 
         * @param {string} [code] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2GetTokenGet(grantType: 'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat', clientId: string, clientSecret?: string, code?: string, username?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2GetTokenGet(grantType, clientId, clientSecret, code, username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查用户是否关联微信
         * @param {number} [uid] 
         * @param {string} [openid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2HasWechatUserGet(uid?: number, openid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2HasWechatUserGet(uid, openid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@getIdToken
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2IdTokenGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2GetIdTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2IdTokenGet(clientId, redirectUri, scope, state, nonce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@jwks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2JwksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2JwksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取访问授权码(企业前后台专用)
         * @param {AppRequestOauth2TokenPassRequest} [appRequestOauth2TokenPassRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest?: AppRequestOauth2TokenPassRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenPassResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 刷新访问令牌
         * @param {AppRequestOauth2RefreshRequest} [appRequestOauth2RefreshRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2RefreshPost(appRequestOauth2RefreshRequest?: AppRequestOauth2RefreshRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2RefreshResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2RefreshPost(appRequestOauth2RefreshRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 撤销访问令牌
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2RevokePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2RevokePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取其他应用临时访问令牌
         * @param {AppRequestOauth2StsRequest} [appRequestOauth2StsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2StsPost(appRequestOauth2StsRequest?: AppRequestOauth2StsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2StsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2StsPost(appRequestOauth2StsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {AppRequestOauth2TokenRequest} [appRequestOauth2TokenRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2TokenPost(appRequestOauth2TokenRequest?: AppRequestOauth2TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2TokenPost(appRequestOauth2TokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取授权中心访问令牌
         * @param {AppRequestOauth2TokenRequestAlias} [appRequestOauth2TokenRequestAlias] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias?: AppRequestOauth2TokenRequestAlias, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseOauth2TokenResponseAlias>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2WechatEventGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2WechatEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOauth2WechatEventPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOauth2WechatEventPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppModelOauth2ClientsApi - factory interface
 * @export
 */
export const AppModelOauth2ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppModelOauth2ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary 权限效验
         * @param {AppRequestOauth2CheckPermissionRequest} [appRequestOauth2CheckPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest?: AppRequestOauth2CheckPermissionRequest, options?: any): AxiosPromise<AppResponseOauth2CheckPermissionResponse> {
            return localVarFp.apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端OSS文件URL签名
         * @param {AppRequestOauth2ClientOssSignUrlRequest} [appRequestOauth2ClientOssSignUrlRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest?: AppRequestOauth2ClientOssSignUrlRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端OSS临时授权码
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientOssStsGet(clientId: string, options?: any): AxiosPromise<AppResponseOauth2ClientOssByKeyResponse> {
            return localVarFp.apiClientOssStsGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信授权登录
         * @param {AppRequestOauth2TokenByWeChatRequest} [appRequestOauth2TokenByWeChatRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest?: AppRequestOauth2TokenByWeChatRequest, options?: any): AxiosPromise<AppResponseOauth2TokenByWeChatResponse> {
            return localVarFp.apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发起授权
         * @param {string} responseType 
         * @param {string} clientId 
         * @param {string} redirectUri 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2AuthorizeGet(responseType: string, clientId: string, redirectUri: string, scope?: string, state?: string, nonce?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2AuthorizeGet(responseType, clientId, redirectUri, scope, state, nonce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信绑定手机号
         * @param {AppRequestOauth2WechatBindMobileRequest} [appRequestOauth2WechatBindMobileRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest?: AppRequestOauth2WechatBindMobileRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量检查关注状态
         * @param {AppRequestOauth2CheckAttentionByUIdsRequest} [appRequestOauth2CheckAttentionByUIdsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest?: AppRequestOauth2CheckAttentionByUIdsRequest, options?: any): AxiosPromise<AppResponseOauth2CheckAttentionByUIdsResponse> {
            return localVarFp.apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查关注状态
         * @param {string} [openid] 
         * @param {number} [uid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CheckAttentionGet(openid?: string, uid?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2CheckAttentionGet(openid, uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取客户端配置信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientInfoGet(clientId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientInfoGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 统计
         * @param {string} [groupBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsCountGet(groupBy?: string, options?: any): AxiosPromise<BbcloudBabyApiResponsesCountResponse> {
            return localVarFp.apiOauth2ClientsCountGet(groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量删除
         * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: any): AxiosPromise<BbcloudBabyApiResponsesBatchDeletionResponse> {
            return localVarFp.apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 列表读取
         * @param {string} [q] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [select] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: any): AxiosPromise<ApiOauth2ClientsDelete200Response> {
            return localVarFp.apiOauth2ClientsGet(q, page, pageSize, orderBy, select, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdDelete(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessCode
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGanAccessCodePost(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsIdGanAccessCodePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary model.App\\Model\\Oauth2\\Clients:ganAccessToken
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGanAccessTokenPost(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsIdGanAccessTokenPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 详情读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdGet(id: number, options?: any): AxiosPromise<AppModelOauth2Clients> {
            return localVarFp.apiOauth2ClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 操作日志读取
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdOperationsLogsGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsIdOperationsLogsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新
         * @param {number} id 
         * @param {AppRequestOauth2ClientsPatchRequest} [appRequestOauth2ClientsPatchRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdPatch(id: number, appRequestOauth2ClientsPatchRequest?: AppRequestOauth2ClientsPatchRequest, options?: any): AxiosPromise<AppModelOauth2Clients> {
            return localVarFp.apiOauth2ClientsIdPatch(id, appRequestOauth2ClientsPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 权限采集
         * @param {number} id 
         * @param {AppRequestOauth2ClientsSyncPermissionRequest} [appRequestOauth2ClientsSyncPermissionRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsIdSyncPermissionPost(id: number, appRequestOauth2ClientsSyncPermissionRequest?: AppRequestOauth2ClientsSyncPermissionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsIdSyncPermissionPost(id, appRequestOauth2ClientsSyncPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建
         * @param {AppRequestOauth2ClientsPostRequest} [appRequestOauth2ClientsPostRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest?: AppRequestOauth2ClientsPostRequest, options?: any): AxiosPromise<AppModelOauth2Clients> {
            return localVarFp.apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 视图脚本读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsSchemaGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsSchemaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 模型状态读取
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsStatesGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2ClientsStatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 指定状态可用操作读取
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2ClientsTransitionsByStateGet(state: string, options?: any): AxiosPromise<BbcloudBabyApiResponsesTransitionByStateResponse> {
            return localVarFp.apiOauth2ClientsTransitionsByStateGet(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2CodeGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2CodeGet(clientId, redirectUri, scope, state, nonce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取微信二维码票据
         * @param {string} [actionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetQrcodeTicketGet(actionInfo?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2GetQrcodeTicketGet(actionInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通过手机短信获取访问令牌
         * @param {string} mobile 
         * @param {string} code 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetTokenBySmsGet(mobile: string, code: string, clientId: string, options?: any): AxiosPromise<AppResponseOauth2TokenBySmsResponse> {
            return localVarFp.apiOauth2GetTokenBySmsGet(mobile, code, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat'} grantType 
         * @param {string} clientId 
         * @param {string} [clientSecret] 
         * @param {string} [code] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2GetTokenGet(grantType: 'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat', clientId: string, clientSecret?: string, code?: string, username?: string, password?: string, options?: any): AxiosPromise<AppResponseOauth2TokenResponse> {
            return localVarFp.apiOauth2GetTokenGet(grantType, clientId, clientSecret, code, username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查用户是否关联微信
         * @param {number} [uid] 
         * @param {string} [openid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2HasWechatUserGet(uid?: number, openid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2HasWechatUserGet(uid, openid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@getIdToken
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {string} [scope] 
         * @param {string} [state] 
         * @param {string} [nonce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2IdTokenGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: any): AxiosPromise<AppResponseOauth2GetIdTokenResponse> {
            return localVarFp.apiOauth2IdTokenGet(clientId, redirectUri, scope, state, nonce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary router.App\\Controller\\Oauth2\\ClientsController@jwks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2JwksGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2JwksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取访问授权码(企业前后台专用)
         * @param {AppRequestOauth2TokenPassRequest} [appRequestOauth2TokenPassRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest?: AppRequestOauth2TokenPassRequest, options?: any): AxiosPromise<AppResponseOauth2TokenPassResponse> {
            return localVarFp.apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 刷新访问令牌
         * @param {AppRequestOauth2RefreshRequest} [appRequestOauth2RefreshRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2RefreshPost(appRequestOauth2RefreshRequest?: AppRequestOauth2RefreshRequest, options?: any): AxiosPromise<AppResponseOauth2RefreshResponse> {
            return localVarFp.apiOauth2RefreshPost(appRequestOauth2RefreshRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 撤销访问令牌
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2RevokePost(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2RevokePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取其他应用临时访问令牌
         * @param {AppRequestOauth2StsRequest} [appRequestOauth2StsRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2StsPost(appRequestOauth2StsRequest?: AppRequestOauth2StsRequest, options?: any): AxiosPromise<AppResponseOauth2StsResponse> {
            return localVarFp.apiOauth2StsPost(appRequestOauth2StsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取访问令牌
         * @param {AppRequestOauth2TokenRequest} [appRequestOauth2TokenRequest] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2TokenPost(appRequestOauth2TokenRequest?: AppRequestOauth2TokenRequest, options?: any): AxiosPromise<AppResponseOauth2TokenResponse> {
            return localVarFp.apiOauth2TokenPost(appRequestOauth2TokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取授权中心访问令牌
         * @param {AppRequestOauth2TokenRequestAlias} [appRequestOauth2TokenRequestAlias] 请求参数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias?: AppRequestOauth2TokenRequestAlias, options?: any): AxiosPromise<AppResponseOauth2TokenResponseAlias> {
            return localVarFp.apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2WechatEventGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2WechatEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信回调
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOauth2WechatEventPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiOauth2WechatEventPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppModelOauth2ClientsApi - object-oriented interface
 * @export
 * @class AppModelOauth2ClientsApi
 * @extends {BaseAPI}
 */
export class AppModelOauth2ClientsApi extends BaseAPI {
    /**
     * 
     * @summary 权限效验
     * @param {AppRequestOauth2CheckPermissionRequest} [appRequestOauth2CheckPermissionRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest?: AppRequestOauth2CheckPermissionRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiCheckPermissionPost(appRequestOauth2CheckPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端OSS文件URL签名
     * @param {AppRequestOauth2ClientOssSignUrlRequest} [appRequestOauth2ClientOssSignUrlRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest?: AppRequestOauth2ClientOssSignUrlRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiClientOssSignUrlPost(appRequestOauth2ClientOssSignUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端OSS临时授权码
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiClientOssStsGet(clientId: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiClientOssStsGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信授权登录
     * @param {AppRequestOauth2TokenByWeChatRequest} [appRequestOauth2TokenByWeChatRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest?: AppRequestOauth2TokenByWeChatRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2AuthByWechatPost(appRequestOauth2TokenByWeChatRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发起授权
     * @param {string} responseType 
     * @param {string} clientId 
     * @param {string} redirectUri 
     * @param {string} [scope] 
     * @param {string} [state] 
     * @param {string} [nonce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2AuthorizeGet(responseType: string, clientId: string, redirectUri: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2AuthorizeGet(responseType, clientId, redirectUri, scope, state, nonce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信绑定手机号
     * @param {AppRequestOauth2WechatBindMobileRequest} [appRequestOauth2WechatBindMobileRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest?: AppRequestOauth2WechatBindMobileRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2BindMobilePost(appRequestOauth2WechatBindMobileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量检查关注状态
     * @param {AppRequestOauth2CheckAttentionByUIdsRequest} [appRequestOauth2CheckAttentionByUIdsRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest?: AppRequestOauth2CheckAttentionByUIdsRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2CheckAttentionByUidsPost(appRequestOauth2CheckAttentionByUIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查关注状态
     * @param {string} [openid] 
     * @param {number} [uid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2CheckAttentionGet(openid?: string, uid?: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2CheckAttentionGet(openid, uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取客户端配置信息
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientInfoGet(clientId: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientInfoGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 统计
     * @param {string} [groupBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsCountGet(groupBy?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsCountGet(groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量删除
     * @param {BbcloudBabyApiRequestsBatchDeletionRequest} [bbcloudBabyApiRequestsBatchDeletionRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest?: BbcloudBabyApiRequestsBatchDeletionRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsDelete(bbcloudBabyApiRequestsBatchDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 列表读取
     * @param {string} [q] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderBy] 
     * @param {string} [select] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsGet(q?: string, page?: number, pageSize?: number, orderBy?: string, select?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsGet(q, page, pageSize, orderBy, select, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary model.App\\Model\\Oauth2\\Clients:ganAccessCode
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdGanAccessCodePost(id: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdGanAccessCodePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary model.App\\Model\\Oauth2\\Clients:ganAccessToken
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdGanAccessTokenPost(id: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdGanAccessTokenPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 详情读取
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdGet(id: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 操作日志读取
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdOperationsLogsGet(id: number, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdOperationsLogsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新
     * @param {number} id 
     * @param {AppRequestOauth2ClientsPatchRequest} [appRequestOauth2ClientsPatchRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdPatch(id: number, appRequestOauth2ClientsPatchRequest?: AppRequestOauth2ClientsPatchRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdPatch(id, appRequestOauth2ClientsPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 权限采集
     * @param {number} id 
     * @param {AppRequestOauth2ClientsSyncPermissionRequest} [appRequestOauth2ClientsSyncPermissionRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsIdSyncPermissionPost(id: number, appRequestOauth2ClientsSyncPermissionRequest?: AppRequestOauth2ClientsSyncPermissionRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsIdSyncPermissionPost(id, appRequestOauth2ClientsSyncPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建
     * @param {AppRequestOauth2ClientsPostRequest} [appRequestOauth2ClientsPostRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest?: AppRequestOauth2ClientsPostRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsPost(appRequestOauth2ClientsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 视图脚本读取
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsSchemaGet(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsSchemaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 模型状态读取
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsStatesGet(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsStatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 指定状态可用操作读取
     * @param {string} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2ClientsTransitionsByStateGet(state: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2ClientsTransitionsByStateGet(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取授权码
     * @param {string} [clientId] 
     * @param {string} [redirectUri] 
     * @param {string} [scope] 
     * @param {string} [state] 
     * @param {string} [nonce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2CodeGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2CodeGet(clientId, redirectUri, scope, state, nonce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取微信二维码票据
     * @param {string} [actionInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2GetQrcodeTicketGet(actionInfo?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2GetQrcodeTicketGet(actionInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通过手机短信获取访问令牌
     * @param {string} mobile 
     * @param {string} code 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2GetTokenBySmsGet(mobile: string, code: string, clientId: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2GetTokenBySmsGet(mobile, code, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取访问令牌
     * @param {'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat'} grantType 
     * @param {string} clientId 
     * @param {string} [clientSecret] 
     * @param {string} [code] 
     * @param {string} [username] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2GetTokenGet(grantType: 'authorization_code' | 'client_credentials' | 'password' | 'dingtalk_code' | 'dingtalk_code_auto' | 'ldap' | 'sms' | 'wechat', clientId: string, clientSecret?: string, code?: string, username?: string, password?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2GetTokenGet(grantType, clientId, clientSecret, code, username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查用户是否关联微信
     * @param {number} [uid] 
     * @param {string} [openid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2HasWechatUserGet(uid?: number, openid?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2HasWechatUserGet(uid, openid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\Oauth2\\ClientsController@getIdToken
     * @param {string} [clientId] 
     * @param {string} [redirectUri] 
     * @param {string} [scope] 
     * @param {string} [state] 
     * @param {string} [nonce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2IdTokenGet(clientId?: string, redirectUri?: string, scope?: string, state?: string, nonce?: string, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2IdTokenGet(clientId, redirectUri, scope, state, nonce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary router.App\\Controller\\Oauth2\\ClientsController@jwks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2JwksGet(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2JwksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取访问授权码(企业前后台专用)
     * @param {AppRequestOauth2TokenPassRequest} [appRequestOauth2TokenPassRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest?: AppRequestOauth2TokenPassRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2PassTokenPost(appRequestOauth2TokenPassRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 刷新访问令牌
     * @param {AppRequestOauth2RefreshRequest} [appRequestOauth2RefreshRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2RefreshPost(appRequestOauth2RefreshRequest?: AppRequestOauth2RefreshRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2RefreshPost(appRequestOauth2RefreshRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 撤销访问令牌
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2RevokePost(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2RevokePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取其他应用临时访问令牌
     * @param {AppRequestOauth2StsRequest} [appRequestOauth2StsRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2StsPost(appRequestOauth2StsRequest?: AppRequestOauth2StsRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2StsPost(appRequestOauth2StsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取访问令牌
     * @param {AppRequestOauth2TokenRequest} [appRequestOauth2TokenRequest] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2TokenPost(appRequestOauth2TokenRequest?: AppRequestOauth2TokenRequest, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2TokenPost(appRequestOauth2TokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取授权中心访问令牌
     * @param {AppRequestOauth2TokenRequestAlias} [appRequestOauth2TokenRequestAlias] 请求参数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias?: AppRequestOauth2TokenRequestAlias, options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2TokenPost_1(appRequestOauth2TokenRequestAlias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信回调
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2WechatEventGet(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2WechatEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信回调
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppModelOauth2ClientsApi
     */
    public apiOauth2WechatEventPost(options?: AxiosRequestConfig) {
        return AppModelOauth2ClientsApiFp(this.configuration).apiOauth2WechatEventPost(options).then((request) => request(this.axios, this.basePath));
    }
}
